<template>
	<v-dialog v-model="show_bid_box" persistent height="auto" width="640" transition="slide-fade" hide-overlay light>
		<v-row no-gutters class="map-header d-flex px-8">
			<v-col sm="6" cols="12" class="d-flex align-center order-sm-1 order-2">
				<img v-if="value.category_id === 1" :src="$store.state.icons.icons['Lumber']" style="height: 36px" alt />
				<img v-if="value.category_id === 2" :src="$store.state.icons.icons['Electric']" style="height: 36px" alt />
				<img v-if="value.category_id === 3" :src="$store.state.icons.icons['Plumbing']" style="height: 36px" alt />
				<img v-if="value.category_id === 4" :src="$store.state.icons.icons['Masonry']" style="height: 36px" alt />
				<img v-if="value.category_id === 5" :src="$store.state.icons.icons['ToolBoxes']" style="height: 36px" alt />
				<img v-if="value.category_id === 6" :src="$store.state.icons.icons['Landscape']" style="height: 36px" alt />

				<div class="pl-7">
					<p class="heading-mf15">{{ value.list_name }}</p>
					<p class="heading-rf13">List ref: {{ value.list_id }}</p>
				</div>
			</v-col>
			<v-col cols="12" sm="6" class="d-flex align-center justify-sm-end order-sm-2 order-1 my-sm-0 my-4">
				<div v-on:click="show_bid_box = false" class="text-tag-btn text-tag-blue d-flex align-center justify-center mr-2">X Close map</div>
			</v-col>
		</v-row>
		<v-divider style="background: #fff"></v-divider>
		<v-row no-gutters>
			<div id="mymap" ref="mymap" style="height: 77vh; width: 640px; background: #fff"></div>
		</v-row>
	</v-dialog>
</template>

<script>
import { Map_Key } from '../../config/variable'
import $Scriptjs from 'scriptjs'

export default {
	props: {
		value: Object,
	},

	data() {
		return {
			show_bid_box: false,
		}
	},

	methods: {
		openMap(data) {
			this.show_bid_box = true
			$Scriptjs('https://maps.googleapis.com/maps/api/js?key=' + Map_Key, () => {
				const location = { lat: data.list_lat, lng: data.list_long }

				const map = new google.maps.Map(document.getElementById('mymap'), {
					zoom: 4,
					center: location,
				})

				const marker = new google.maps.Marker({
					position: location,
					map: map,
				})
			})

			// this.initMap()
		},

		openMapRoot() {
			this.show_bid_box = true

			$Scriptjs('https://maps.googleapis.com/maps/api/js?key=' + Map_Key, () => {
				this.initMap()
			})
		},

		async initMap() {
			var myorigin = {
				lat: this.value.bid_lat,
				lng: this.value.bid_long,
			}

			var mydestination = {
				lat: this.value.list_lat,
				lng: this.value.list_long,
			}

			let icon = JSON.parse(JSON.stringify(this.$store.state.icons.icons['Artboard1']))

			var directionsRenderer = new google.maps.DirectionsRenderer()
			var directionsService = new google.maps.DirectionsService()

			var map = new google.maps.Map(document.getElementById('mymap'), {
				zoom: 3,
				center: myorigin,
			})

			var marker = new google.maps.Marker({
				position: mydestination,
				map: map,
				icon: icon,
			})

			var marker = new google.maps.Marker({
				position: myorigin,
				map: map,
			})

			directionsRenderer.setMap(map)
			directionsRenderer.setOptions({ suppressMarkers: true })

			this.calculateAndDisplayRoute(directionsService, directionsRenderer, myorigin, mydestination)
		},

		calculateAndDisplayRoute(directionsService, directionsRenderer, myorigin, mydestination) {
			var selectedMode = 'DRIVING'
			directionsService.route(
				{
					origin: myorigin,
					destination: mydestination,
					travelMode: google.maps.TravelMode[selectedMode],
				},

				function (response, status) {
					if (status == 'OK') {
						directionsRenderer.setDirections(response)
					}
				}
			)
		},

		// getMapSrc() {
		// 	let Latitude = this.value.list_lat;
		// 	let Longitude = this.value.list_long;
		// 	let Latitude_bid = this.value.bid_lat;
		// 	let Longitude_bid = this.value.bid_long;
		// 	let height = 380;
		// 	let width = 640;
		// 	let icon = JSON.parse(
		// 		JSON.stringify($store.state.icons.icons["Artboard1"])
		// 	);

		// 	let url =
		// 		"https://maps.googleapis.com/maps/api/staticmap?center=" +
		// 		Latitude +
		// 		"," +
		// 		Longitude +
		// 		"&zoom=11&maptype=roadmap&size=" +
		// 		width +
		// 		"x" +
		// 		height +
		// 		"&scale=1" +
		// 		"&markers=icon:" +
		// 		icon +
		// 		"%7C" +
		// 		+Latitude +
		// 		"," +
		// 		Longitude +
		// 		"&key=" +
		// 		Map_Key;

		// 	return url;
		// }
	},
}
</script>

<style lang="scss" scoped>
.map-header {
	z-index: 9;
	content: '';
	height: 80px;
	background: #ffffff;

	.text-tag-btn {
		height: 32px;
		width: 127px;
		cursor: pointer;
		font-family: Inter-Medium;
		font-size: 15px;
		color: #216fff;
	}
}

.map-view {
	overflow: hidden;
}

@media only screen and (max-width: 600px) {
	.map-header {
		height: 120px;
	}
}
</style>
